body {
  font-family: "Roboto", Arial, sans-serif;
}

button {
  cursor: pointer;
  font-family: "Roboto", Arial, sans-serif;
  /*font-size: 1rem;*/
  font-weight: 500;
}

h1 {
  font-family: "Roboto Condensed", "Roboto", Arial, sans-serif;
  font-size: 3.815rem;
  font-weight: 700;
  margin-bottom: 3rem;
}

h2 {
  font-family: "Roboto Condensed", "Roboto", Arial, sans-serif;
  font-size: 1.953rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.563rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
}

h4 {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
}
